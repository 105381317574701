import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from "@angular/common/http";
import { AppConfigService } from "../service";
import { Observable } from "rxjs";
import { CommonConstant } from "../constants";

@Injectable({
    providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(private configService: AppConfigService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Ignore Login and Get Token
        // console.log('calling this',window.location.href);
        if (this.configService._config.publicAPIs.some((x) => x === req.url) || req.url.indexOf("amazonaws.com") > -1) {
            req = req.clone({ headers: req.headers.set("Accept", "*/*") });
            return next.handle(req);
        }
        const urls = req.url && req.url.includes('?') ? req.url.split('?')[0] : '';
        if (this.configService._config.publicAPIs.some((x) => x === urls)) {
            req = req.clone({ headers: req.headers.set("Accept", "*/*") });
            return next.handle(req);
        }
        req = req.clone({ headers: req.headers.set("Authorization", `${window.sessionStorage.getItem(CommonConstant.token)}`) });
        // console.log('token',`${window.sessionStorage.getItem(CommonConstant.token)}`)
        req = req.clone({ headers: req.headers.set("SCHOOL_CODE", `${window.sessionStorage.getItem(CommonConstant.orgCode)}`) });
        // console.log("org code", `${window.sessionStorage.getItem(CommonConstant.orgCode)}`);
        if((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            req = req.clone({ headers: req.headers.set("APP_NAME", "SmartParent") });
            req = req.clone({ headers: req.headers.set("STUDENT_PROFILE_ID", `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`) });
        } else {
            req = req.clone({ headers: req.headers.set("APP_NAME", "Spectrum") });
        }        req = req.clone({ headers: req.headers.set("Accept", "*/*") });
        return next.handle(req);
    }
}
