import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Category, Lookup, LookupCategory, Tree } from "models";
import { CommonAPIConstant } from '../constants';
import { BaseService } from './base.service';
import { CRUDService } from './crud.service';
import { CommonUtility } from '../utilities';
import { AppConfigService } from './config.service';
import { Exam } from 'projects/model/examination';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class LookupService extends CRUDService<Lookup> {
    constructor(protected baseService: BaseService, private configService: AppConfigService,private http: HttpClient) {
        super(baseService, `${configService._config.apiPath}${CommonAPIConstant.lookup}`);
    }

    lookup(fieldName: LookupCategory): Observable<string[]> {
        const reqParams = CommonUtility.convertObjectToParams({ fieldName });
        return this.baseService.get<string[]>(`${this.configService._config.apiPath}${CommonAPIConstant.lookup}?${reqParams}`);
    }

    updateLookUp(data: any): Observable<any> {
        let url: string = this.apiPath;
        return this.baseService.put<any>(url, data);
    }

    addLookUp(data: any): Observable<any> {
        let url: string = this.apiPath;
        return this.baseService.post<any>(url, data);
    }

    classroom(): Observable<Tree[]> {
        return this.baseService.get<Tree[]>(`${this.configService._config.apiPath}${CommonAPIConstant.classrooms}`);
    }
    classrooms(requestedSchoolCd: any): Observable<Tree[]> {
        let headers = new HttpHeaders();
    
        if (requestedSchoolCd && requestedSchoolCd.schoolCd) {
            headers = headers.set('REQUESTED_SCHOOL_CD', requestedSchoolCd.schoolCd);
        }
        
        return this.http.get<Tree[]>(`${this.configService._config.apiPath}${CommonAPIConstant.classrooms}`, {
            headers: headers
        });
    }
    
    

    getinstituteType(): Observable<any> {
        return this.baseService.get<any>(`${this.configService._config.apiPath}${CommonAPIConstant.instituteType}`);
    }

    defaultClassroom(instituteType): Observable<any[]> {
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.defaultClassroom}?instituteType=${instituteType}`);
    }

    getAcademicYears(): Observable<string[]> {
        return this.baseService.get<string[]>(`${this.configService._config.apiPath}${CommonAPIConstant.academicYears}`);
    }

    getExamination(classroomId: number): Observable<Exam[]> {
        const reqParams = CommonUtility.convertObjectToParams({ classroomId });
        return this.baseService.get<Exam[]>(`${this.configService._config.apiPath}${CommonAPIConstant.examination}?${reqParams}`);
    }

    getSkills(data: object): Observable<Category[]> {
        const reqParams = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<Category[]>(`${this.configService._config.apiPath}${CommonAPIConstant.skills}?${reqParams}`);
    }

    getFeeHeads(showInactive: any): Observable<any[]> {
        const reqParams = CommonUtility.convertObjectToParams({showInactive});
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.feeHeads}?${reqParams}`);
    }

    feeHeadsForPayment(params: any): Observable<any[]> {
        const reqParams = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.feeHeadsForPayment}?${reqParams}`);
    }

    role(): Observable<any[]> {
        return this.baseService.post<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.role}`, '');
    }

    getExamTypes(): Observable<string[]> {
        const reqParams = CommonUtility.convertObjectToParams({ fieldName: 'EXAM_TYPE' });
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.examType}?${reqParams}`);
    }

    getEnquiryStatus(): Observable<string[]> {
        const reqParams = CommonUtility.convertObjectToParams({ fieldName: LookupCategory.ENQUIRY_STATUS });
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.lookup}?${reqParams}`);
    }

    getAcademicYearsWithParam(params: any): Observable<string[]> {
        const reqParams = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<string[]>(`${this.configService._config.apiPath}${CommonAPIConstant.academicYears}?${reqParams}`);
    }

    getOutstandingFeeHeads(): Observable<any[]> {
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.outstandingFeeHeads}`);
    }

    category(): Observable<Tree[]> {
        return this.baseService.get<Tree[]>(`${this.configService._config.apiPath}${CommonAPIConstant.category}/dropdown`);
    }

    multipleCategory(): Observable<Tree[]> {
        return this.baseService.get<Tree[]>(`${this.configService._config.apiPath}${CommonAPIConstant.otherIncomeExpenseCategory}/all`);
    }

    getFeeHeadsDropdown(params: any): Observable<any[]> {
        const reqParams = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<any[]>(`${this.configService._config.apiPath}${CommonAPIConstant.feeHeadsDropdown}`);
    }

    lookupDefaultSetUp(fieldName: LookupCategory): Observable<string[]> {
        const reqParams = CommonUtility.convertObjectToParams({ fieldName });
        return this.baseService.get<string[]>(`${this.configService._config.apiPath}${CommonAPIConstant.lookupSetup}?${reqParams}`);
    }

    createDefaultCourses(data:any): Observable<string[]> {
        let url: string = `${this.configService._config.apiPath}${CommonAPIConstant.createDefaultCourses}`;
        return this.baseService.post<any>(url, data);
    }

    getClassroomByTemplateAndExamType(templateName, examType): Observable<Tree[]> {
        return this.baseService.get<Tree[]>(`${this.configService._config.apiPath}${CommonAPIConstant.classroomsByTemplate}?templateName=${templateName}&examType=${examType}`);
    }
}
